<template>
  <MyAccountCheckAuthLoggedIn>
    <MaterialsGrid
      class="sela-container"
      fetch-route="/nuxt-api/user/materials/wishlist"
      :wishlist="true"
    />
  </MyAccountCheckAuthLoggedIn>
</template>
<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()

useHead({
  title: t('wishlist'),
  link: [useSeoCanonical(url + '/my-account/wishlist')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('wishlist'), url + '/my-account/wishlist')
    ])
  ]
})
</script>
